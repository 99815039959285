import React from "react";
import "./Honors.scss";

import NavigationBar from "../../_components/NavigationBar/NavigationBar";
import uom from "../../_images/UniversityLogo/uom_coloured.png";
import passleader from "../../_images/HonorsImage/pass_leader.png";
import studentrep from "../../_images/HonorsImage/student_rep.png";

const Honors = () => {
  return (
    <div>
      <NavigationBar />
      <table className="container-honors">
        <thead>
          <tr>
            <td colSpan="2">
              <p className="heading-font-honors">Honors</p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="honors-image">
              <img src={uom} width="140px" />
            </td>
            <td className="honors-text">
              <h>
                <a
                  href="https://www.stellify.manchester.ac.uk/stellify-ug/"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                >
                  The Stellify Award
                </a>
              </h>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a style={{ fontSize: "12px" }}>
                  Award by the University of Manchester, 2022
                </a>
              </div>
              <p style={{ fontSize: "12px" }}>
                I participated in over 25 hours of volunteering activities, and
                served as a{" "}
                <a
                  href={passleader}
                  style={{ fontSize: "12px", textDecoration: "none" }}
                >
                  Student Rep
                </a>
                &nbsp;and a&nbsp;
                <a
                  href={studentrep}
                  style={{ fontSize: "12px", textDecoration: "none" }}
                >
                  PASS Leader
                </a>
                . I also completed the Ethical Grand Challenge activities. The
                Stellify Award was awarded for these achievements.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Honors;
