import React from "react";
import { NavLink } from "react-router-dom";
import { FloatButton, Dropdown } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./NavigationBar.scss";

const items = [
  {
    key: "1",
    label: <NavLink to="/">Home</NavLink>,
  },
  {
    key: "2",
    label: <NavLink to="/publications">Publications</NavLink>,
  },
  {
    key: "3",
    label: <NavLink to="/talks">Talks</NavLink>,
  },
  {
    key: "4",
    label: <NavLink to="/teaching">Teaching</NavLink>,
  },
  {
    key: "5",
    label: <NavLink to="/experience">Experience</NavLink>,
  },
  {
    key: "6",
    label: <NavLink to="/projects">Projects</NavLink>,
  },
  {
    key: "7",
    label: <NavLink to="/honors">Honors</NavLink>,
  },
];

export default function NavigationBar() {
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-element">
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink
                  to="/publications"
                  style={{ pointerEvents: "none", cursor: "default" }}
                >
                  Publication
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/talks"
                  style={{ pointerEvents: "none", cursor: "default" }}
                >
                  Talks
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/teaching"
                  style={{ pointerEvents: "none", cursor: "default" }}
                >
                  Teaching
                </NavLink>
              </li>
              <li>
                <NavLink to="/experience">Experience</NavLink>
              </li>
              <li>
                <NavLink to="/projects">Projects</NavLink>
              </li>
              <li>
                <NavLink to="/honors">Honors</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <FloatButton.BackTop shape="circle" />
      </nav>
      <FloatButton.Group>
        <div className="dropdown-container">
          <Dropdown menu={{ items }} placement="topRight">
            <FloatButton
              shape="circle"
              trigger="hover"
              icon={
                <MenuOutlined style={{ color: "#1677ff" }} theme="outlined" />
              }
            />
          </Dropdown>
        </div>
        <br />
        <FloatButton.BackTop shape="circle" />
      </FloatButton.Group>
    </>
  );
}
