import React from "react";
import "./Experience.scss";

import NavigationBar from "../../_components/NavigationBar/NavigationBar";

import nus from "../../_images/UniversityLogo/nus.png";
import ucl from "../../_images/UniversityLogo/ucl.png";
import microsoft from "../../_images/CompanyLogo/microsoft.png";
import carbonlinking from "../../_images/CompanyLogo/carbonlinking.png";
import pembroke from "../../_images/UniversityLogo/pembroke.png";
import uom from "../../_images/UniversityLogo/uom_coloured.png";

const Experience = () => {
  return (
    <div>
      <NavigationBar />
      <table className="container-experience">
        <thead>
          <tr>
            <td colSpan="2">
              <p className="heading-font-experience">
                Research & Work Experiences
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          {/* -------------------- */}
          {/* NUS */}
          {/* -------------------- */}
          <tr>
            <td className="experience-image">
              <img src={nus} width="140px" />
            </td>
            <td className="experience-text">
              <p>
                <h style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Internship - SNSPDs Simulation and Magic-angle Twisted BLG
                  Fabrication
                </h>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a style={{ fontSize: "12px" }}>
                  IFIM, National University of Singapore
                </a>
                <a style={{ fontSize: "12px" }}>Apr 1st - Aug 25th, 2024</a>
              </div>
              <div style={{ fontSize: "12px" }}>
                Advised by Prof. Denis Bandurin
              </div>
              <p style={{ fontSize: "12px" }}>
                Studied on Nanowire/SNSPDs simulation [
                <a
                  href="https://github.com/ZewenKong/SNSPDs-Simulation"
                  style={{ fontSize: "12px", textDecoration: "none" }}
                >
                  GitHub Repo
                </a>
                ] and magic-angle twisted bilayer graphene devices, gaining
                experience in analog circuit design (LTspice) and
                characterisation methods (Optical Microscope and AFM).
              </p>
            </td>
          </tr>
          {/* -------------------- */}
          {/* Cam */}
          {/* -------------------- */}
          <tr>
            <td className="experience-image">
              <img src={pembroke} width="140px" />
            </td>
            <td className="experience-text">
              <p>
                <h style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Summer Research - Email Filtering Based on ML Algorithms
                </h>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a style={{ fontSize: "12px" }}>
                  Pembroke College, Cambridge University
                </a>
                <a style={{ fontSize: "12px" }}>Jul 1st - Aug 2nd, 2022</a>
              </div>
              <p style={{ fontSize: "12px" }}>
                Enrolled in a computer science project,the kNN and SVM
                algorithms were used to filter spam emails by analysing the text
                and images in the emails. Several Python packages for text and
                image analysis were utilised.
              </p>
            </td>
          </tr>
          {/* -------------------- */}
          {/* UoM */}
          {/* -------------------- */}
          <tr>
            <td className="experience-image">
              <img src={uom} width="140px" />
            </td>
            <td className="experience-text">
              <p>
                <h style={{ fontWeight: "bold", fontSize: "12px" }}>
                  UG Project - Inorganic/Organic Hybrid Nanoparticles Experiment
                </h>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a style={{ fontSize: "12px" }}>The University of Manchester</a>
                <a style={{ fontSize: "12px" }}>Feb - May, 2022</a>
              </div>
              <div style={{ fontSize: "12px" }}>
                Advised by Dr. Lee A. Fielding
              </div>
              <p style={{ fontSize: "12px" }}>
                Synthesised the core/shell polymer & silica hybrid nanoparticles
                for coating application. Characterised the products properties
                using FTIR (product confirmation), DSC (glass transition
                temperature determine), TGA (polymerisation conversion
                measurement) and DLS (nano-particle size and stability).
              </p>
            </td>
          </tr>
          {/* -------------------- */}
          <tr>
            <td className="experience-image">
              <img src={uom} width="140px" />
            </td>
            <td className="experience-text">
              <p>
                <h style={{ fontWeight: "bold", fontSize: "12px" }}>
                  Summer Research - Viscoelasticity and Polymer Swelling
                  Experiment
                </h>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a style={{ fontSize: "12px" }}>The University of Manchester</a>
                <a style={{ fontSize: "12px" }}>Jun - Aug, 2021</a>
              </div>
              <div style={{ fontSize: "12px" }}>
                Advised by Dr. Charles Darko
              </div>
              <p style={{ fontSize: "12px" }}>
                Collated viscoelasticity-related formulas and processed data
                from swelling experiments to investigate the effects of
                crosslink density on the swell ratio (Flory-Rehner’s equation)
                and mechanical properties.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Experience;
