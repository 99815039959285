import React from "react";
import "./Introduction.scss";
import profileImage from "../../../_images/profile.png";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaStackOverflow } from "react-icons/fa6";

const Introduction = () => {
  return (
    <table className="container-introduction">
      <tbody>
        <tr>
          <td className="container-introduction-1">
            <p>
              <name className="name-introduction">Zewen Kong「孔泽文」</name>
            </p>
            <p>
              <a className="link-introduction">
                M.Res student in{" "}
                <a
                  href="https://www.imperial.ac.uk/study/courses/postgraduate-taught/soft-electronic-materials/"
                  className="link-text-introduction"
                >
                  Soft Electronic Materials
                </a>
              </a>
              <br />
            </p>
            <p className="link-introduction">
              <strong className="link-introduction">Email: </strong>
              zewen.kong@outlook.com
              {/* <br />
              <a className="link-introduction">Office: </a>
              NUS S9, 4 Science Drive 2, Singapore, 117544 */}
            </p>
            {/*  */}
            {/* Social Links */}
            {/*  */}
            <p className="container-introduction-2">
              <FaLinkedin className="icon-introduction" />
              <a
                href="https://www.linkedin.com/in/zewen-kong-07b42b263/"
                className="link-text-introduction"
              >
                LinkedIn
              </a>
              &nbsp;&nbsp;
              <FaGithub className="icon-introduction" />
              <a
                href="https://github.com/ZewenKong"
                className="link-text-introduction"
              >
                GitHub
              </a>
              &nbsp;&nbsp;
              <FaStackOverflow className="icon-introduction" />
              <a
                href="https://stackoverflow.com/users/20741646/zewenkong"
                className="link-text-introduction"
              >
                Stack Overflow
              </a>
            </p>
            {/* Academic Links */}
            {/*  */}
            <p>
              <a className="link-text-introduction-2">Publications</a>&nbsp;
              <a className="link-text-introduction-2">|</a>&nbsp;
              {/*  */}
              <a className="link-text-introduction-2">Talks</a>&nbsp;
              <a className="link-text-introduction-2">|</a>&nbsp;
              {/*  */}
              <a className="link-text-introduction-2">Teaching</a>&nbsp;
              <a className="link-text-introduction-2">|</a>&nbsp;
              {/*  */}
              <Link to="/experience" className="link-text-introduction-2">
                Experience
              </Link>
              &nbsp;<a className="link-text-introduction-2">|</a>&nbsp;
              {/*  */}
              <Link to="/projects" className="link-text-introduction-2">
                Projects
              </Link>
            </p>
            <p style={{ fontSize: "12px" }}>
              Per aspera ad astra「循此苦旅，以达星辰」
            </p>
          </td>
          <td className="container-introduction-3">
            <a className="image-frame-introduction">
              <img
                className="image-introduction"
                alt="profile photo"
                src={profileImage}
              />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Introduction;
