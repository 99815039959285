import React from "react";

import "./Journey.scss";

import nus from "../../../_images/UniversityLogo/nus.png";
import ucl from "../../../_images/UniversityLogo/ucl.png";
import uom from "../../../_images/UniversityLogo/uom_coloured.png";
import nusIntern from "../../../_images/nus-intern.png";

const Journey = () => {
  const openPhotographInNewWindow = () => {
    window.open(nusIntern, "_blank", "noopener,noreferrer");
  };

  return (
    <table className="container-journey">
      <heading className="heading-font-journey">My Journey</heading>
      <tbody>
        <tr>
          <td className="journey-image">
            <img src={nus} width="140px" />
          </td>
          <td className="journey-text">
            <h style={{ fontWeight: "bold", fontSize: "12px" }}>
              Internship in the I-FIM at the National University of Singapore,
              2024
            </h>
            <p style={{ fontSize: "12px" }}>
              I interned at the I-FIM (Institute for Functional Intelligent
              Materials) at the National University of Singapore, supervised by
              Prof. Denis Bandurin. Studied the simulation of SNSPDs through
              analog circuits and the fabrication of magic-angle twisted bilayer
              graphene. [
              <span
                onClick={openPhotographInNewWindow}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "12px",
                }}
              >
                Journey Photograph
              </span>
              ]
            </p>
          </td>
        </tr>
        <tr>
          <td className="journey-image">
            <img src={ucl} width="140px" />
          </td>
          <td className="journey-text">
            <papertilte style={{ fontWeight: "bold", fontSize: "12px" }}>
              Graduated from the University College London, 2023
            </papertilte>
            <p style={{ fontSize: "12px" }}>
              I graduated from the University College London with an MSc degree
              in Computer Science, awarding a{" "}
              <strong style={{ fontSize: "12px", color: "green" }}>
                Merit degree (65%)
              </strong>
              .
            </p>
          </td>
        </tr>
        <tr>
          <td className="journey-image">
            <img src={uom} width="140px" />
          </td>
          <td className="journey-text">
            <tilte style={{ fontWeight: "bold", fontSize: "12px" }}>
              Graduated from the University of Manchester, 2022
            </tilte>
            <p style={{ fontSize: "12px" }}>
              I graduated from the University of Manchester with a BSc (Hons)
              degree in Materials Science and Engineering, awarding a&nbsp;
              <strong style={{ fontSize: "12px", color: "green" }}>
                First-Class degree (77%)
              </strong>
              . My GPA was 75% in Year 1, 81% in Year 2, and 75% in Year 3.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Journey;
