import React from "react";
import "./Projects.scss";

import NavigationBar from "../../_components/NavigationBar/NavigationBar";

import ucl from "../../_images/UniversityLogo/ucl.png";
import microsoft from "../../_images/CompanyLogo/microsoft.png";
import carbonlinking from "../../_images/CompanyLogo/carbonlinking.png";

const Projects = () => {
  return (
    <div>
      <NavigationBar />
      <table className="container-projects">
        <thead>
          <tr>
            <td colSpan="2">
              <p className="heading-font-projects">Projects</p>
            </td>
          </tr>
        </thead>
        <tbody>
          {/* -------------------- */}
          {/* UCL */}
          {/* -------------------- */}
          <tr>
            <td className="projects-image">
              <img src={ucl} width="140px" style={{ marginBottom: "5px" }} />
              <img src={microsoft} width="140px" style={{ marginTop: "5px" }} />
            </td>
            <td className="projects-text">
              <h style={{ fontWeight: "bold", fontSize: "12px" }}>
                PG Project -{" "}
                <a
                  href="https://techcommunity.microsoft.com/t5/educator-developer-blog/multiplayer-gaming-experiences-in-microsoft-teams-using-live/ba-p/3951741"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                >
                  Microsoft X UCL Teams Carnival
                </a>
              </h>
              <br />
              {/*
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a style={{ fontSize: "14px" }}>University College London</a>
                <a style={{ fontSize: "14px" }}>Jun 15th - Sep 14th, 2023</a>
              </div>
              */}
              <p style={{ fontSize: "12px" }}>
                <a style={{ fontSize: "12px" }}>Programming Languages:</a>
                &nbsp;C# (C-Sharp), JavaScript
                <br />
                <a style={{ fontSize: "12px" }}>Techniques and Tools:</a>
                &nbsp;React, Unity
              </p>
              <p style={{ fontSize: "12px" }}>
                Developed and integrated Unity games into Microsoft Teams by
                utilising the Microsoft LiveShare SDK and React, to achieve
                multiplayer gaming experiences within Microsoft Teams.
              </p>
            </td>
          </tr>
          {/* -------------------- */}
          {/* UCL */}
          {/* -------------------- */}
          <tr>
            <td className="projects-image">
              <img
                src={carbonlinking}
                width="140px"
                style={{ marginBottom: "5px" }}
              />
            </td>
            <td className="projects-text">
              <h style={{ fontWeight: "bold", fontSize: "12px" }}>
                Web Development -&nbsp;
                <a
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textDecoration: "none",
                  }}
                >
                  B2B Campaign Web Platform
                </a>
              </h>
              <br />
              {/*
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a
                  style={{ fontSize: "14px", textDecoration: "none" }}
                  href="https://www.carbonlinking.com/"
                >
                  Carbon Linking Limited
                </a>
                <a style={{ fontSize: "14px" }}>Jan 14th - May 1st, 2023</a>
              </div>
              */}
              <p style={{ fontSize: "12px" }}>
                <a style={{ fontSize: "12px" }}>Programming Languages:</a>&nbsp;
                JavaScript
                <br />
                <a style={{ fontSize: "12px" }}>Techniques and Tools:</a>&nbsp;
                NodeJS, React, Redux Reducer, Socket.io, and MongoDB
              </p>
              <p style={{ fontSize: "12px" }}>
                Developed a B2B campaign web platform for&nbsp;
                <a
                  style={{ fontSize: "12px", textDecoration: "none" }}
                  href="https://www.carbonlinking.com/"
                >
                  Carbon Linking Limited
                </a>
                .
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Projects;
