import React from "react";
import "./Homepage.scss";
import { FloatButton } from "antd";

import AboutMe from "../../_components/HomepageComponents/AboutMe/AboutMe";
import Introduction from "../../_components/HomepageComponents/Introduction/Introduction";
import Journey from "../../_components/HomepageComponents/Journey/Journey";

const Homepage = () => {
  return (
    <div className="container">
      <Introduction />
      <AboutMe />
      <Journey />
      <FloatButton.BackTop shape="circle" />
    </div>
  );
};

export default Homepage;
