
import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Homepage from './_pages/Homepage/Homepage.jsx';
import ExperiencePage from "./_pages/Experience/Experience.jsx";
import HonorsPage from "./_pages/Honors/Honors.jsx";
import PublicationsPage from "./_pages/Publications/Publications.jsx";
import TalksPage from "./_pages/Talks/Talks.jsx";
import TeachingPage from "./_pages/Teaching/Teaching.jsx";
import ProjectsPage from "./_pages/Projects/Projects.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} exact />
        <Route path="/experience" element={<ExperiencePage />} />
        <Route path="/honors" element={<HonorsPage />} />
        <Route path="/publications" element={<PublicationsPage />} />
        <Route path="/talks" element={<TalksPage />} />
        <Route path="/teaching" element={<TeachingPage />} />
        <Route path="/projects" element={<ProjectsPage />} />s
      </Routes>
    </Router> 
  );
}

export default App;
